import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { defaultTagsData, extraTagsData } from '../../../data/data';

function MainNav() {
    const navigate = useNavigate();

    // Function to handle tag click
    const handleTagClick = (tag) => {
        if (tag === "Home") {
            navigate(`/`);
        } else {
            // Navigate to the news/results page with the selected tag as a query parameter
            navigate(`/news/results?tag=${encodeURIComponent(tag)}`);
        }
    };

    return (
        <>
            <Navbar className="bg-body-tertiary" style={{fontSize:'0.5rem'}}>
                <Container>
                    <div className="d-flex flex-wrap justify-content-center w-100">
                    <button
                                className="btn btn-light mx-1 tagsForHome " 
                                onClick={() => handleTagClick('#LatestNews')}
                                style={{ cursor: "pointer", fontWeight: "bold",fontSize:'0.8rem',borderRadius:'0rem' }} // Keep boldness for default tags
                                aria-label={`Navigate to #LatestNews`} // Accessibility
                            >
                                #LATESTNEWS
                            </button>
                        {defaultTagsData.map((tag, index) => (
                            <button
                                key={index}
                                className="btn btn-light mx-1 tagsForHome"
                                onClick={() => handleTagClick(tag)}
                                style={{ cursor: "pointer", fontWeight: "bold",fontSize:'0.8rem',borderRadius:'0rem'  }} // Keep boldness for default tags
                                aria-label={`Navigate to ${tag}`} // Accessibility
                            >
                                {tag}
                            </button>
                        ))}
                    </div>
                </Container>
            </Navbar>
            <Navbar className="bg-body-secondary">
                <Container>
                    <div className="d-flex flex-wrap justify-content-center w-100">
                        {extraTagsData.map((tag, index) => (
                            <button
                                key={index}
                                className="btn btn-light mx-1 tagsForHome"
                                onClick={() => handleTagClick(tag)}
                                style={{ cursor: "pointer", fontWeight: "normal", backgroundColor: "transparent", border: "none", color: "inherit" ,fontSize:'0.8rem',borderRadius:'0rem' }} // Normal font weight and no hover effect
                                aria-label={`Navigate to ${tag}`} // Accessibility
                            >
                                {tag}
                            </button>
                        ))}
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default MainNav;
