import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Form, ListGroup, Overlay } from 'react-bootstrap';
import logo from '../../../assets/ctv.png';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../myfirestore';  // Adjust the path to your config file

const SocialMediaIcons = () => (
  <>
    <Navbar.Text>
      <a href="/" aria-label="Home">
        <i className="bi bi-house-door-fill text-danger"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="instagram.com/teluguctvhttps://www.youtube.com/redirect?event=channel_description&redir_token=QUFFLUhqbUM4OWoza1llek9vTzYzaVEwb3VKY0FxalhqZ3xBQ3Jtc0tuMHBfWm1lZzhmdk5RckxkQ1JjTUJCOEw1dGdGUThrWVIyOUYtZ1BoaXR1NVF2RkpYMHFBM0xuY2RsTTVTRkdYSVVsVjdrS1JwcTlQYkx5d3VlQmp1R0xUWUZfeUdtY0ZXRzZoWXRlSldsZUlkVWNtOA&q=https%3A%2F%2Fwww.instagram.com%2Fteluguctv%2F" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <i className="bi bi-instagram text-danger"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://www.youtube.com/redirect?event=channel_description&redir_token=QUFFLUhqbS1aeFZ6Wks5VEtGWkVicF8xRExLRnpjZ1c4d3xBQ3Jtc0tsa1YwbVF5d0dWZjc5X0syd3NDSG8yMy1aWGlaUXFpNnpiNXZjN3NraDQ3cmdFTU04ZHFEQVM3NVJwT0FSZkZyekFRYW50QUg0SzlZWExjTWkyNS1BeHlxNTJjUmRCRmtlUks2VXVrb2lRNTVobUNBOA&q=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100094523364164%26is_tour_completed%3Dtrue" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
        <i className="bi bi-facebook text-primary"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://www.youtube.com/redirect?event=channel_description&redir_token=QUFFLUhqbjRMeWlwRFd3bnp2OFFfSVBReFhWWGpiVWpHUXxBQ3Jtc0tsVnRfc2lSNmJMeVA0U1J0UXo3SXBWWUpKMVY0eFlCMEk0LWEyNHZQdF9zS1pEcjQ1YkRDRUthRjZrMElEbmQ3U1o2cVFJY2wxTWxFa1VJdmpJSDhJX2x0TzBJbUExZkVTaHdHVGJEZjd3T0ZhbXJqdw&q=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fteluguctv%2F%3ForiginalSubdomain%3Din" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <i className="bi bi-linkedin text-info"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
    <a href="https://www.youtube.com/redirect?event=channel_description&redir_token=QUFFLUhqbEpSNHpZdG42b2t0dl83TzlDVTY1M2pkUGk1d3xBQ3Jtc0ttbHlQOHdIbFNDRjhKVkpSNmdzTllqeVZVRE0wdU9TVGwzbl84U2lvLTA0dGpISnl1NUhJOExvbDRRUUZJdFVJYTJjQTFyWXJRbnJPcTdMdmZZMUpSQ2gtZ01HeVhwbGsxdWtDRkxKVlU3U3QzcTVIaw&q=https%3A%2F%2Ftwitter.com%2Fteluguctv" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
        <i className="bi bi-twitter text-primary"></i>
    </a>
</Navbar.Text>

    <Navbar.Text>
      <a href="https://www.youtube.com/@teluguctv" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
        <i className="bi bi-youtube text-danger"></i>
      </a>
    </Navbar.Text>
    {/* <Navbar.Text>
      <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
        <i className="bi bi-whatsapp text-success"></i>
      </a>
    </Navbar.Text> */}
  </>
);

function MainNav() {
  const [searchQuery, setSearchQuery] = useState('');
  const [articles, setArticles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  // Organized Andhra Pradesh and Telangana districts
  const districts = [
    {
      state: 'Andhra Pradesh', 
      districts: [
        'Anantapur', 'Chittoor', 'East Godavari', 'Guntur', 'Kadapa', 
        'Krishna', 'Kurnool', 'Nellore', 'Prakasam', 'Srikakulam', 
        'Visakhapatnam', 'Vizianagaram', 'West Godavari','Alluri Sitharama Raju',
        'Anakapalli',
        'Parvathipuram Manyam',
        'Srikakulam',
        'Visakhapatnam',
        'Vizianagaram',  
        'Bapatla',
        'Dr. B. R. Ambedkar Konaseema',
        'East Godavari',  
        'Eluru',
        'Guntur',
        'Kakinada',
        'Krishna',
       'NTR',
        'Palnadu',
        'Prakasam',
        'Sri Potti Sriramulu Nellore',
        'West Godavari',    
        'Anantapur',
        'Annamayya',
        'Chittoor',
        'YSR (Kadapa)',
        'Kurnool',
        'Nandyal',
        'Sri Sathya Sai',
        'Tirupati'
      ]
    },
    {
      state: 'Telangana', 
      districts: [
        'Adilabad', 'Hyderabad', 'Karimnagar', 'Khammam', 'Mahabubnagar', 
        'Medak', 'Nalgonda', 'Nizamabad', 'Rangareddy', 'Warangal','Adilabad',
        'Bhadradri Kothagudem Hanumakonda', 
        'Hyderabad',
        'Jagtial Jangaon',
        'Jayashankar Bhupalpally',
        'Jogulamba Gadwal',
        'Kamareddy', 
        'Karimnagar',
        'Khammam', 
        'Kumuram Bheem',
        'Mahabubabad', 
        'Mahabubnagar', 
        'Mancherial',
        'Medak', 
        'Medchal',
        'Malkajgiri', 
        'Mulugu',
        'Nagarkurnool', 
        'Nalgonda',
        'Narayanpet', 
        'Nirmal',
        'Nizamabad', 
        'Peddapalli',
        'Rajanna Sircilla',
        'Rangareddy',
        'Sangareddy',
        'Siddipet',
        'Suryapet',
        'Vikarabad', 
        'Wanaparthy',
        'Warangal',
        'Yadadri', 
        'Bhuvanagiri'
      ]
    }
  ];

  // Handle district selection
  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    if (selectedDistrict) {
      navigate(`/news/results?district=${selectedDistrict}`);
    }
  };
  // Function to fetch all articles from Firestore
  const fetchAllArticles = async () => {
    const articlesRef = collection(db, 'news');
    const querySnapshot = await getDocs(articlesRef);
    const articles = [];
    querySnapshot.forEach((doc) => {
      articles.push({ id: doc.id, ...doc.data() });
    });
    return articles;
  };

  // Function to filter articles by search term
  const filterAndScoreArticles = (articles, searchTerm) => {
    const lowerCaseSearch = searchTerm.toLowerCase();
  
    // Score articles based on search term matches in specific fields
    const scoredArticles = articles
      .filter((article) => article.approved === true) // Only include approved articles
      .map((article) => {
        let score = 0;
  
        // Increment score for each field that matches the search term
        if (article.title?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.content?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.author?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.category?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.date?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.description?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.districtName?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.synopsis?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.type?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.tags?.some((tag) => tag.toLowerCase().includes(lowerCaseSearch))) score++;
  
        return { ...article, score }; // Include score in each article object
      });
  
    // Sort first by score (descending) and then by date (most recent first)
    return scoredArticles
      .sort((a, b) => b.score - a.score || new Date(b.date) - new Date(a.date)) // Sort by score, then by date
      .slice(0, 10); // Return the top 10 articles
  };
  
  

  // Handle search input change
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      const allArticles = await fetchAllArticles();
      const results = filterAndScoreArticles(allArticles, query);
      setArticles(results);
      setShowDropdown(results.length > 0);
    } else {
      setShowDropdown(false);
      setArticles([]);
    }
  };

  // Navigate to article details
  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
    setShowDropdown(false); // Close dropdown after navigation
  };

  return (
    <Navbar className="bg-body-tertiary shadow-sm border">
      <Container fluid className='px-2'>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="CTV News Logo" height="50" className="d-inline-block align-top" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end gap-3 fs-5">
          <SocialMediaIcons />
          <Form.Select 
            className="w-auto" 
            aria-label="Select District" 
            onChange={handleDistrictChange}
          >
            <option value="">Select District</option>
            {districts.map((group, idx) => (
              <optgroup label={group.state} key={idx}>
                {group.districts.map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </optgroup>
            ))}
          </Form.Select>
          <Form.Control
            ref={searchInputRef}
            type="text"
            placeholder="Search news..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-auto"
            aria-label="Search"
          />
          {/* Search Results Dropdown */}
          <Overlay
            target={searchInputRef.current}
            show={showDropdown}
            placement="bottom"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex: 1000,
                  ...props.style,
                }}
              >
                <ListGroup>
                  {articles.length > 0 ? (
                    articles.map((article) => (
                      <ListGroup.Item
                        key={article.id}
                        action
                        onClick={() => handleArticleClick(article.id)}
                      >
                        {article.title}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No articles found.</ListGroup.Item>
                  )}
                </ListGroup>
              </div>
            )}
          </Overlay>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;