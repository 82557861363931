import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/Home';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Article from './components/news/article/Article';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import Signin from './components/auth/Signin';
import Newsupload from './components/upload/Newsupload';
import Admincp from './components/upload/Admincp';
import NewsResults from './components/news/news-results/NewsResults';
import AboutUs from './components/aboutUs/AboutUs';
import AdvertiseWithUs from './components/advertise/AdvertiseWithUs';
import Disclaimer from './components/disclaimer/Disclaimer';
import Policy from './components/policy/Policy';
import { useState, useEffect } from 'react';
import { auth, db } from './myfirestore'; // Import Firebase config
import { onAuthStateChanged } from 'firebase/auth'; // Firebase auth state change listener
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore query functions


function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); // To store user role
  const [loading, setLoading] = useState(true); // To track if user data is loading

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Fetch user data from Firestore based on email
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("email", "==", currentUser.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].data();
            setUser({
              displayName: currentUser.displayName,
              email: currentUser.email,
              uid: currentUser.uid,
              role: userDoc.role, // Fetch role from Firestore
            });
            setRole(userDoc.role); // Set role
          } else {
            setUser(null); // No user data found
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
      } else {
        setUser(null);
        setRole(null); // Reset role when logged out
      }
      setLoading(false); // Set loading to false once data is fetched
    });

    return () => unsubscribe();
  }, []);

  // Function to check if the user has the required role for a route
  const hasPermission = (requiredRole) => {
    return user && role === requiredRole;
  };

  // Only render the RouterProvider if loading is complete
  if (loading) {
    return <div>Loading...</div>; // You can show a loading spinner or message
  }

  // Routes
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/article/:id",
      element: <Article />,
    },
    {
      path: "/auth/signin",
      element: <Signin />,
    },
    {
      path: "/upload",
      element: hasPermission("admin") || hasPermission("manager") ? <Newsupload /> : <Navigate to="/" />, // Only Admin or Manager can upload
    },
    {
      path: "/admincp",
      element: hasPermission("admin") ? <Admincp /> : <Navigate to="/" />, // Only Admin can access Admin CP
    },
    {
      path: "news/results",
      element: <NewsResults />,
    },
    {
      path: "/aboutUs",
      element: <AboutUs />,
    },
    {
      path: "/advertise",
      element: <AdvertiseWithUs />,
    },
    {
      path: "/disclaimer",
      element: <Disclaimer />,
    },
    {
      path: "/policy",
      element: <Policy />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
