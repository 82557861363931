import Card from 'react-bootstrap/Card';
import { Stack } from 'react-bootstrap';
import React from 'react';
import './top.css';
import { useNavigate } from 'react-router-dom';
import { truncateString } from '../../../utilities/truncate';

function TopMainCard({ data }) {
  const navigate = useNavigate();

  // Style for the overlay with media query for mobile view
  const overlayStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30%', // Adjust height as needed
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0))',
    color: 'white',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '1rem',
    opacity: 0, // Start hidden
    transition: 'opacity 0.3s ease',
  };

  // Card style
  const cardStyle = {
    position: 'relative',
    height: '22rem',
    overflow: 'hidden',
     cursor: "pointer",backgroundColor:'#f3f3f3' 
  };

  return (
    <React.Fragment>
      <Card  
        style={cardStyle}
        className='mt-2 shadow h-100 scrollable-card  hover-red'
        onClick={() => { navigate("/article/" + data.id); }}
        onMouseEnter={(e) => {
          const overlay = e.currentTarget.querySelector('.overlay');
          if (overlay) overlay.style.opacity = 1; // Show overlay on hover
        }}
        onMouseLeave={(e) => {
          const overlay = e.currentTarget.querySelector('.overlay');
          if (overlay) overlay.style.opacity = 0; // Hide overlay on mouse leave
        }}
      >
        <Card.Img variant="top" src={data.imageUrl} className="scrolling-image" style={{ height: '80%', objectFit: 'cover', transition: 'transform 0.3s ease-in-out' }}  />
       
         <Card.Body style={{height:'20%'}}>
         <Stack>
            <Card.Text className='fw-bold'>{data.title}</Card.Text>
          </Stack>
         </Card.Body>
       
      </Card>
    </React.Fragment>
  );
}

export default TopMainCard;
