import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { defaultTagsData } from '../../../data/data';
import './categoryGrid.css';  // CSS file for custom styles

function CategoryGrid() {
  const [newsData, setNewsData] = useState({});
  const navigate = useNavigate();
  const db = getFirestore();

  const defaultTags = defaultTagsData;

  useEffect(() => {
    const fetchNews = async () => {
      const newsByTag = {};

      for (const tag of defaultTags) {
        const q = query(
          collection(db, 'news'),
          where('tags', 'array-contains', tag.toLowerCase())
        );
        const querySnapshot = await getDocs(q);

        newsByTag[tag] = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title,
            imageUrl: data.imageUrl, // Assuming image URL is stored in Firestore
            tags: data.tags.map(t => t.toLowerCase())
          };
        });
      }

      setNewsData(newsByTag);
    };

    fetchNews();
  }, [db, defaultTags]);

  const handleShowMore = (tag) => {
    navigate(`/news/results?tag=${tag}`);
  };

  const groupedTags = [];
  for (let i = 0; i < defaultTags.length; i += 4) {
    groupedTags.push(defaultTags.slice(i, i + 4));
  }

  return (
    <Container>
      {groupedTags.map((tagGroup, rowIndex) => (
        <Row key={rowIndex} className="mb-4">
          {tagGroup.map((tag, colIndex) => {
            return tag.toUpperCase() !== "#LATESTNEWS" && (
              <Col key={colIndex} md={3}>
                <Card className="category-card">
                  <Card.Body>
                    <Card.Title className="category-title text-uppercase">{tag}</Card.Title>
                    <ul className="news-list">
                      {newsData[tag] && newsData[tag].length > 0 ? (
                        newsData[tag].slice(0, 4).map((news, newsIndex) => (
                          <li
                            key={newsIndex}
                            onClick={() => navigate(`/article/${news.id}`)}
                            className="news-item"
                          >
                            {newsIndex === 0 && news.imageUrl ? (
                              <Image src={news.imageUrl} alt={news.title} fluid className="news-image mb-2" />
                            ) : null}
                            <div className="news-text fw-bold" style={{fontSize:'0.85rem'}}>
                              {news.title}
                              <hr />
                            </div>
                          </li>
                        ))
                      ) : (
                        Array.from({ length: 4 }).map((_, index) => (
                          <li key={index} className="news-item placeholder">
                            &nbsp; {/* Adds a blank line to maintain spacing */}
                            <hr />
                          </li>
                        ))
                      )}
                    </ul>
                    <Button
                      variant="outline-danger"
                      className="show-more-btn"
                      onClick={() => handleShowMore(tag)}
                    >
                      Show More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      ))}
    </Container>
  );
}

export default CategoryGrid;
