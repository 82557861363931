import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../myfirestore";
import { defaultTagsData, extraTagsData } from "../../data/data";

function Newsupload() {
  const districtOptions = [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Kadapa",
    "Krishna",
    "Kurnool",
    "Nellore",
    "Prakasam",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
    "Alluri Sitharama Raju",
    "Anakapalli",
    "Parvathipuram Manyam",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "Bapatla",
    "Dr. B. R. Ambedkar Konaseema",
    "East Godavari",
    "Eluru",
    "Guntur",
    "Kakinada",
    "Krishna",
    "NTR",
    "Palnadu",
    "Prakasam",
    "Sri Potti Sriramulu Nellore",
    "West Godavari",
    "Anantapur",
    "Annamayya",
    "Chittoor",
    "YSR (Kadapa)",
    "Kurnool",
    "Nandyal",
    "Sri Sathya Sai",
    "Tirupati",
    "Adilabad",
    "Hyderabad",
    "Karimnagar",
    "Khammam",
    "Mahabubnagar",
    "Medak",
    "Nalgonda",
    "Nizamabad",
    "Rangareddy",
    "Warangal",
    "Adilabad",
    "Bhadradri Kothagudem Hanumakonda",
    "Hyderabad",
    "Jagtial Jangaon",
    "Jayashankar Bhupalpally",
    "Jogulamba Gadwal",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Kumuram Bheem",
    "Mahabubabad",
    "Mahabubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Malkajgiri",
    "Mulugu",
    "Nagarkurnool",
    "Nalgonda",
    "Narayanpet",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Rangareddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal",
    "Yadadri",
    "Bhuvanagiri",
  ];

  const [newsData, setNewsData] = useState({
    title: "",
    author: "",
    date: "",
    type: "",
    category: "",
    synopsis: "",
    sections: [{ header: "", description: "" }], // Updated to hold multiple sections
    district: "",
    imageFile: null,
    videoFile: null,
    tags: [],
    approved: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "tags") {
      setNewsData({
        ...newsData,
        [name]: value.split(",").map((tag) => tag.trim()),
      });
    } else if (name === "imageFile") {
      if (files[0]) {
        setNewsData({
          ...newsData,
          imageFile: files[0],
        });
        setImagePreview(URL.createObjectURL(files[0]));
      }
    } else if (name === "videoFile") {
      if (files[0]) {
        setNewsData({
          ...newsData,
          videoFile: files[0],
        });
        setVideoPreview(URL.createObjectURL(files[0]));
      }
    } else {
      setNewsData({
        ...newsData,
        [name]: value,
      });
    }
  };

  const handleSectionChange = (index, e) => {
    const { name, value } = e.target;
    const newSections = [...newsData.sections];
    newSections[index][name] = value; // Update specific section's header or description
    setNewsData({ ...newsData, sections: newSections });
  };

  const addSection = () => {
    setNewsData({
      ...newsData,
      sections: [...newsData.sections, { header: "", description: "" }],
    });
  };

  const removeSection = (index) => {
    const newSections = newsData.sections.filter((_, i) => i !== index);
    setNewsData({ ...newsData, sections: newSections });
  };

  const handleTagsChange = (e) => {
    const value = e.target.value;

    setInputValue(value);

    // Filter tags based on the input value (case-insensitive search)

    const filteredSuggestions = [...defaultTagsData, ...extraTagsData].filter(
      (tag) => tag.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleAddTag = (tag) => {
    if (!newsData.tags.includes(tag)) {
      setNewsData({
        ...newsData,

        tags: [...newsData.tags, tag],
      });

      setInputValue(""); // Clear input field after adding

      setSuggestions([]); // Hide suggestions after adding
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const storage = getStorage();

    try {
      let imageUrl = "";
      let videoUrl = "";

      if (newsData.imageFile) {
        const imageRef = ref(storage, `images/${newsData.imageFile.name}`);
        if (newsData.imageFile.size > 5 * 1024 * 1024) {
          throw new Error("Image file size must be less than 5 MB.");
        }
        await uploadBytes(imageRef, newsData.imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      if (newsData.videoFile) {
        const videoRef = ref(storage, `videos/${newsData.videoFile.name}`);
        if (newsData.videoFile.size > 20 * 1024 * 1024) {
          throw new Error("Video file size must be less than 20 MB.");
        }
        await uploadBytes(videoRef, newsData.videoFile);
        videoUrl = await getDownloadURL(videoRef);
      }

      await addDoc(collection(db, "news"), {
        title: newsData.title,
        author: newsData.author,
        date: newsData.date,
        type: newsData.type,
        category: newsData.category,
        synopsis: newsData.synopsis,
        sections: newsData.sections, // Include sections in the document
        districtName: newsData.district,
        imageUrl,
        videoUrl,
        tags: newsData.tags,
        approved: false,
      });

      alert("News article successfully uploaded!");
      setNewsData({
        title: "",
        author: "",
        date: "",
        type: "",
        category: "",
        synopsis: "",
        sections: [{ header: "", description: "" }], // Reset to initial state
        district: "",
        imageFile: null,
        videoFile: null,
        tags: [],
        approved: false,
      });
      setImagePreview("");
      setVideoPreview("");
    } catch (e) {
      console.error("Error adding document: ", e);
      setError(e.message || "Error uploading article. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Upload News Article</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Title */}
        <div className="form-group mb-3">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={newsData.title}
            onChange={handleChange}
            required
          />
        </div>

        {/* Author */}
        <div className="form-group mb-3">
          <label htmlFor="author">Author</label>
          <input
            type="text"
            className="form-control"
            id="author"
            name="author"
            value={newsData.author}
            onChange={handleChange}
            required
          />
        </div>

        {/* Date */}
        <div className="form-group mb-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={newsData.date}
            onChange={handleChange}
            required
          />
        </div>

        {/* Type */}
        <div className="form-group mb-3">
          <label htmlFor="type">Type</label>
          <select
            className="form-control"
            id="type"
            name="type"
            value={newsData.type}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="Featured">CTV Specials</option>
            <option value="Common">Latest News</option>
            <option value="Flash">Orginals</option>
            <option value="Top">Top News</option>
            <option value="Short">వెబ్ స్టోరీస్</option>
          </select>
        </div>

        {/* District Dropdown */}
        <div className="form-group mb-3">
          <label htmlFor="district">District Name</label>
          <select
            className="form-control"
            id="district"
            name="district"
            value={newsData.district}
            onChange={handleChange}
            required
          >
            <option value="">Select District</option>
            {districtOptions.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </select>
        </div>

        {/* Dynamic Sections */}
        <h3>Article Sections</h3>
        {newsData.sections.map((section, index) => (
          <div key={index} className="mb-3 border p-3 rounded">
            <div className="form-group">
              <label htmlFor={`header-${index}`}>Section Header</label>
              <input
                type="text"
                className="form-control"
                id={`header-${index}`}
                name="header"
                value={section.header}
                onChange={(e) => handleSectionChange(index, e)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor={`description-${index}`}>
                Section Description
              </label>
              <textarea
                className="form-control"
                id={`description-${index}`}
                name="description"
                value={section.description}
                onChange={(e) => handleSectionChange(index, e)}
                rows="3"
                required
              ></textarea>
            </div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeSection(index)}
            >
              Remove Section
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={addSection}
        >
          Add Section
        </button>

        {/* Tags Input Field */}

        <div className="form-group mb-3">
          <label htmlFor="tags">Tags (comma-separated)</label>

          <input
            type="text"
            className="form-control"
            id="tags"
            name="tags"
            value={inputValue}
            onChange={handleTagsChange}
          />

          {suggestions.length > 0 && inputValue && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleAddTag(suggestion)}
                  className="suggestion-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Sections */}
        {/* 
        <div className="form-group mb-3">

          <label>Sections</label>

          {newsData.sections.map((section, index) => (

            <div key={index} className="mb-3">

              <input

                type="text"

                className="form-control mb-2"

                name="header"

                placeholder="Section Header"

                value={section.header}

                onChange={(e) => handleSectionChange(index, e)}

                required

              />

              <textarea

                className="form-control"

                name="description"

                placeholder="Section Description"

                value={section.description}

                onChange={(e) => handleSectionChange(index, e)}

                required

              />

              <button type="button" className="btn btn-danger mt-2" onClick={() => removeSection(index)}>Remove Section</button>

            </div>

          ))}

          <button type="button" className="btn btn-primary" onClick={addSection}>Add Section</button>

        </div> */}

        {/* Image Upload */}
        <div className="form-group mb-3">
          <label htmlFor="imageFile">Image File</label>
          <input
            type="file"
            className="form-control"
            id="imageFile"
            name="imageFile"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              className="img-thumbnail mt-2"
            />
          )}
        </div>

        {/* Video Upload */}
        <div className="form-group mb-3">
          <label htmlFor="videoFile">Video File</label>
          <input
            type="file"
            className="form-control"
            id="videoFile"
            name="videoFile"
            accept="video/*"
            onChange={handleChange}
          />
          {videoPreview && (
            <video controls className="mt-2" width="100%">
              <source src={videoPreview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Uploading..." : "Upload Article"}
        </button>
      </form>
    </div>
  );
}

export default Newsupload;
