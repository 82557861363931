import React, { useRef, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import './scrolling.css';
import { useNavigate } from 'react-router-dom';

function ScrollingCard({ data }) {
  const scrollRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(0); // Start index for visible items
  const cardRef = useRef(null); // Ref to measure card width
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate the width of the first card after the component mounts
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [data]);

  const scrollLeft = () => {
    // Move index to the left, but not below 0
    setVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const scrollRight = () => {
    // Move index to the right, but not past the end of the data array
    setVisibleIndex((prevIndex) => Math.min(prevIndex + 1, data.length - 4));
  };

  // Slice the data to show only 4 items starting from the visibleIndex
  const visibleItems = data.slice(visibleIndex, visibleIndex + 4);

  return (
    <div className="scrolling-container position-relative">
      <Button variant="outline-secondary" className="scroll-btn left-btn" onClick={scrollLeft} disabled={visibleIndex === 0}>
        &#10094;
      </Button>
      <div className="scrolling-wrapper d-flex overflow-hidden" ref={scrollRef}>
        {visibleItems.map((item, index) => (
          <Card
            onClick={() => navigate('/article/' + item.id)}
            key={index}
            className="scrolling-card shadow-sm mx-2"
            ref={index === 0 ? cardRef : null}
          >
            {item.type === 'video' ? (
              <video width="100%" height="180" controls>
                <source src={item.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <Card.Img variant="top" src={item.imageUrl} className="scrolling-image" />
            )}
            <Card.Body>
              <Card.Title className="fs-6 text-center hover-red">{item.title}</Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Button
        variant="outline-secondary"
        className="scroll-btn right-btn"
        onClick={scrollRight}
        disabled={visibleIndex + 4 >= data.length}
      >
        &#10095;
      </Button>
    </div>
  );
}

export default ScrollingCard;
