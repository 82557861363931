import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import "./featured.css";
import { truncateString } from "../../../utilities/truncate";

function FeaturedCard({ data }) {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle card click to navigate to the article page
  const handleCardClick = () => {
    navigate(`/article/${data.id}`); // Redirect to article/:id page
  };

  return (
    <Card
      className="my-3 shadow-sm d-flex flex-row "
      onClick={handleCardClick}
      style={{ cursor: "pointer",backgroundColor:'#f3f3f3' }}
    >
      {/* Image on the left */}
      <Card.Img
        variant="left"
        src={data.imageUrl}
        alt={data.title}
        className="card-image"
        style={{ width: "40%", objectFit: "cover" }}
      />

      {/* Text content on the right */}
      <Card.Body className="p-3 d-flex align-items-center">
        <Card.Text
          className=" hover-red mb-0 fw-bold"
          style={{ fontSize: "0.65rem" }}
        >
          {truncateString(data.title, 46)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FeaturedCard;
