import React from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

function TopSubCard({ data }) {
  const navigate = useNavigate();

  // Handle card click to navigate to article page
  const handleCardClick = () => {
    navigate(`/article/${data.id}`); // Redirect to article/:id page
  };

  return (
    <Card
      className="h-100 d-flex flex-row"
      onClick={handleCardClick}
      style={{ cursor: "pointer",backgroundColor:'#f3f3f3' }}
    >
      {/* Image on the left */}
      <Card.Img
        variant="left"
        src={data.imageUrl}
        alt={data.title}
        style={{ width: '40%', objectFit: 'cover' }}
      />

      {/* Text content on the right */}
      <Card.Body className="d-flex align-items-center p-2">
        <Card.Text className="hover-red fw-bold" style={{ fontSize: '0.72rem', margin: 0 }}>
          {data.title}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default TopSubCard;
