import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MainNav from "../../utilities/navbar/MainNav";
import SecondNav from "../../utilities/navbar/SecondNav";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../myfirestore";
import { doc, getDoc, collection, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import "./article.css";

const sortByRecent = (array) => array.sort((a, b) => new Date(b.date) - new Date(a.date));

function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [subArticles, setSubArticles] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const docRef = doc(db, "news", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const articleData = { id: docSnap.id, ...docSnap.data() };
          setArticle(articleData);

          // Fetch related articles based on tags
          if (articleData.tags && articleData.tags.length > 0) {
            const relatedArticlesQuery = query(
              collection(db, "news"),
              where("tags", "array-contains-any", articleData.tags.slice(0, 5))
            );
            const relatedArticlesSnapshot = await getDocs(relatedArticlesQuery);
            const relatedArticlesData = relatedArticlesSnapshot.docs
              .filter((doc) => doc.id !== id)
              .map((doc) => ({ id: doc.id, ...doc.data() }));
            setRelatedArticles(sortByRecent(relatedArticlesData));
          }
        }

        // Fetch sub-articles of type "Featured"
        const subArticlesCollection = collection(db, "news");
        const subArticlesSnapshot = await getDocs(subArticlesCollection);
        let subArticlesData = subArticlesSnapshot.docs
          .filter((doc) => doc.data().type === "Featured")
          .map((doc) => ({ id: doc.id, ...doc.data() }));
        subArticlesData = sortByRecent(subArticlesData.slice(0, 5));
        setSubArticles(subArticlesData);

        // Fetch latest news for "Latest News" section
        const latestNewsQuery = query(collection(db, "news"), orderBy("date", "desc"), limit(5));
        const latestNewsSnapshot = await getDocs(latestNewsQuery);
        const latestNewsData = latestNewsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLatestNews(latestNewsData);
      } catch (error) {
        console.error("Error fetching article data: ", error);
      }
    };

    fetchArticleData();
  }, [id]);

  return (
    <React.Fragment>
      <MainNav />
      <SecondNav />
      <Container fluid className=" shadow p-4 mt-4 article-container">
        <Row>
          {/* Main Article Section */}
          <Col lg={10} md={12} >
            {article ? (
              <div className="main-article ">
                <h1 className="article-title">{article.title}</h1>
                <p className="text-muted article-meta">
                  {article.author} | {new Date(article.date).toLocaleDateString()}
                </p>
                <div className="d-flex justify-content-center ">
                  {article.videoUrl ? (
                    <video controls width="100%" className="article-video">
                      <source src={article.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Image
                      src={article.imageUrl}
                      alt="Main Article"
                      fluid
                      rounded
                      className="mb-4 article-image"
                    />
                  )}
                </div>
                <div className="article-containerX " > 
                  {article.sections.map((section, index) => (
                    <div key={index} className="article-sectionX">
                      <h3 className="article-headerX">{section.header}</h3>
                      <p className="article-descriptionX">{section.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>Loading main article...</p>
            )}

            {/* Related Articles Section */}
            {relatedArticles.length > 0 && (
              <div className="related-articles mt-5">
                <h2>Related Articles</h2>
                <Row>
                  {relatedArticles.map((relatedArticle) => (
                    <Col key={relatedArticle.id} lg={4} md={6} className="mb-4">
                      <Card
                        className="border"
                        onClick={() => navigate("/article/" + relatedArticle.id)}
                      >
                        <Card.Img
                          variant="top"
                          src={relatedArticle.imageUrl}
                          className="sub-article-image"
                        />
                        <Card.Body>
                          <Card.Title className="sub-article-title">
                            {relatedArticle.title}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            {/* Latest News Section */}
            {latestNews.length > 0 && (
              <div className="latest-news mt-5">
                <h2>Latest News</h2>
                <Row>
                  {latestNews.map((news) => (
                    <Col key={news.id} lg={4} md={6} className="mb-4">
                      <Card
                        className="border"
                        onClick={() => navigate("/article/" + news.id)}
                      >
                        <Card.Img
                          variant="top"
                          src={news.imageUrl}
                          className="sub-article-image"
                        />
                        <Card.Body>
                          <Card.Title className="sub-article-title">{news.title}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </Col>

          {/* Sub Articles Section */}
          <Col lg={2} md={12}>
            {/* {subArticles.length > 0 ? (
              subArticles.map((subArticle) => (
                <Card
                  key={subArticle.id}
                  className="border mb-3"
                  onClick={() => navigate("/article/" + subArticle.id)}
                >
                  <Card.Img
                    variant="top"
                    src={subArticle.imageUrl}
                    className="sub-article-image"
                  />
                  <Card.Body>
                    <Card.Title className="sub-article-title">{subArticle.title}</Card.Title>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No sub-articles found.</p>
            )} */}
            ads
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Article;
