export const defaultTagsData = [
  "తెలంగాణ",
  "ఆంధ్రప్రదేశ్",
  "సినిమా",
  "బిజినెస్",
  "జాబ్స్ & ఎడ్యుకేషన్",
  "లైఫ్ స్టైల్",
  "ఫోటోలు",
  "వీడియోలు",
 
];
export const extraTagsData = [
  "షార్ట్ న్యూస్",
  "Big Boss 8",
  "Local News",
  "ఆస్ట్రాలజీ",
  "క్రీడలు",
  "టెక్నాలజీ",
  "జాతీయం-అంతర్జాతీయం",
  "వెబ్‌స్టోరీస్",
  "ట్రెండింగ్",
];