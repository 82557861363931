
import FeaturedCard from './FeaturedCard';
const sortByRecent = (array) => {
  return array.sort((a, b) => new Date(b.date) - new Date(a.date));  // Assuming 'date' field exists
};
function Featured({data}) {
  var dataS=data.slice(0,11)
  dataS=sortByRecent(dataS)
  return (
    <div className='p-3'>
        {
              dataS.map((ele,idx)=><div key={idx}><FeaturedCard  data={ele}></FeaturedCard></div>)
            }
            
    </div>
  );
}

export default Featured;