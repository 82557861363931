import React, { useState, useEffect } from "react";
import { auth, db } from "../../myfirestore"; // Import Firebase config
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore"; // Firestore functions
import { onAuthStateChanged } from "firebase/auth"; // Listen for auth state changes

const Signin = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  // Use onAuthStateChanged to listen for auth changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Query Firestore for the user by email
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("email", "==", currentUser.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].data();
            setUser({
              displayName: currentUser.displayName,
              email: currentUser.email,
              uid: currentUser.uid,
              role: userDoc.role, // Assuming role is stored in Firestore
            });
          } else {
            setError("User data not found in the database.");
          }
        } catch (err) {
          setError("Error fetching user data: " + err.message);
        }
      } else {
        setUser(null);
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // The onAuthStateChanged hook will automatically trigger and fetch user data after login
    } catch (err) {
      setError("Error logging in with Google: " + err.message);
    }
  };

  return (
    <div>
      {!user ? (
        <div>
          <button onClick={loginWithGoogle}>Login with Google</button>
          {error && <p style={{ color: "red" }}>{error}</p>} {/* Show error message */}
        </div>
      ) : (
        <div>
          <h3>Welcome {user.displayName}</h3>
          <p>Email: {user.email}</p>
          <p>Role: {user.role}</p>
        </div>
      )}
    </div>
  );
};

export default Signin;
