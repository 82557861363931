import React from "react";
// import { Link } from "react-router-dom"; 
// import Container from "react-bootstrap/Container";
// import Navbar from "react-bootstrap/Navbar";
// import { Nav } from "react-bootstrap";

function AdminNav() {
  return (
    <React.Fragment>
      {/* <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/" style={{ cursor: "pointer" }}>
            News Website
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/auth/signin">Sign In</Nav.Link>
              <Nav.Link as={Link} to="/upload">Upload News</Nav.Link>
              <Nav.Link as={Link} to="/admincp">Admin CP</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      */}
    </React.Fragment>
  );
}

export default AdminNav;
